<template>
    <div>
        <div class="box">
            <el-form :model="queryParams" ref="queryForm" :inline="true" label-position="left">
                <el-form-item :label="td('ERP单号')" prop="billNo">
                    <el-input v-model="queryParams.billNo" :placeholder="td('请输入ERP单号')" clearable size="small" />
                </el-form-item>

                <el-form-item label="">
                    <el-select clearable filterable size="small" v-model="queryParams.opFlag" :placeholder="td('处理状态')">
                        <el-option v-for="item in is_orNotHandle" :key="item.id" :label="item.name" :value="item.id" />
                    </el-select>
                </el-form-item>

                <el-form-item label=" ">
                    <el-select clearable filterable multiple collapse-tags size="small"
                        v-model="queryParams.orderExceptionType" :placeholder="td('异常类型')">
                        <el-option v-for="item in tf('EXCEPTION_TYPE')" :key="item.detailId" :label="item.langTranslate"
                            :value="item.detailId" />
                    </el-select>
                </el-form-item>

                <el-form-item label=" ">
                    <el-button type="primary" size="small" @click="handleQuery(queryParams)">{{ td("搜索") }}</el-button>
                    <el-button size="small" type="primary" plain @click="resetQuery">{{ td("重置") }}</el-button>
                </el-form-item>
            </el-form>
        </div>

        <div class="threeBtns">
            <el-button class="btn1" icon="el-icon-upload2" type="primary" size="small" @click="handleImport"
                :disabled="plList.length > 1 || plList <= 0">{{ td("处理")
                }}</el-button>
        </div>


        <el-table :data="getTableList" tooltip-effect="dark"
            :header-cell-style="{ background: '#F9FCFF', color: '#323233' }" @selection-change="handleSelectionChange"
            ref="table">
            <el-table-column type="selection" align="center" />
            <el-table-column prop="depotName" :label='td("预报单信息")' width="240">
                <template slot-scope="scope">
                    <p style="margin: 0;text-align: left;">{{ td('入库单号') }}：
                        <el-link type="primary" :underline="false" @click="toogleExpand(scope.row)">
                            {{ scope.row.billNo }}
                        </el-link>
                    </p>
                    <p style="margin: 0;text-align: left;">{{ td('物流公司') }}：<span>{{ scope.row.logisticsCompany }}</span></p>
                    <p style="margin: 0;text-align: left;">{{ td('物流单号') }}：<span>{{ scope.row.wayBillNo }}</span></p>
                </template>
            </el-table-column>
            <el-table-column prop="imageUrl" :label='td("图片")'>
                <template slot-scope="scope">
                <!-- <img :src="scope.row.orderExceptionUrl " style="width: 50px; height: 50px" /> -->
                <el-image
                :src="scope.row.curImg"
                :preview-src-list="scope.row.imgUrls">
                <div slot="error" class="image-slot">
                    未上传图片
                </div>
                </el-image>
                </template>
            </el-table-column>
            <el-table-column prop="depotAddress" :label='td("仓库")'>
                <template slot-scope="scope">
                    {{ td(scope.row.depotName) }}
                </template>
            </el-table-column>
            <el-table-column :label="td('预报时间')" align="center" prop="preTime" width="160">
                <template slot="header">
                    <p style="margin: 0;text-align: left;">{{ td('预报入库时间') }}</p>
                    <p style="margin: 0;text-align: left;">{{ td('登记时间') }}</p>
                </template>
                <template slot-scope="scope">
                    <p style="margin: 0;text-align: left;">
                        <span>{{ parseTime(scope.row.inTime) }}</span>
                    </p>
                    <p style="margin: 0;text-align: left;">
                        <span>{{ parseTime(scope.row.registerTime) }}</span>
                    </p>
                </template>
            </el-table-column>
            <el-table-column :label="td('入库类型')" align="center">
                <template slot-scope="scope">
                    {{ tfp('WMS_INORDER_TYPE', scope.row.inorderType) }}
                </template>
            </el-table-column>
            <el-table-column :label="td('异常类型')" align="center">
                <template slot-scope="scope">
                    {{ scope.row.orderExceptionTypeName }}
                </template>
            </el-table-column>
            <el-table-column :label="td('异常原因')" align="center">
                <template slot-scope="scope">
                    {{ scope.row.excepCause }}
                </template>
            </el-table-column>
            <el-table-column :label="td('处理意见备注')" align="center">
                <template slot-scope="scope">
                    {{ scope.row.custComments }}
                </template>
            </el-table-column>
            <el-table-column :label="td('是否处理')" align="center">
                <template slot-scope="scope">
                    <span v-if="scope.row.opFlag == 0">{{ "未处理" }}</span>
                    <span v-else>{{ "已处理" }}</span>
                </template>
            </el-table-column>
            <el-table-column type="expand" width="1">
                <template slot-scope="scope">
                    <el-table key="id" class="table-in-table" :data="sonSkuListss.get(scope.row.id)" style="width: 100%;"
                        row-key="id" border>
                        <el-table-column type="index" :label="td('序号')" width="50">
                        </el-table-column>
                        <el-table-column :label="td('货品')">
                            <template slot-scope="scope">
                                <span>{{ scope.row.goodsName }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="SELLER_SKU">
                            <template slot-scope="scope">
                                <span>{{ scope.row.custGoodsCode }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="仓库SKU">
                            <template slot-scope="scope">
                                <span>{{ scope.row.goodsBarcode }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="异常类型">
                            <template slot-scope="scope">
                                <span>{{ tfp('EXCEPTION_TYPE', scope.row.orderExceptionDetailType) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="问题数量">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.exceptionNum" disabled />
                            </template>
                        </el-table-column>
                        <el-table-column label="处理方案">
                            <template slot-scope="scope">
                                <span>{{ tfp('EXCEPTION_HANDLE', scope.row.handleScheme) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="处理状态">
                            <template slot-scope="scope">
                                <span>{{ scope.row.handleState ? '已处理' : '未处理' }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <br>
                </template>
            </el-table-column>
        </el-table>

        <pagination :total="td(total)" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize"
            @pagination="getDepotInfo" />

        <!--处理异常单-->
        <el-dialog :close-on-click-modal="false" :title="title" width="1000px" :visible.sync="open_op" append-to-body>
            <el-form ref="form" class="my-form" :model="form" :rules="rules">
                <el-form-item>
                    <el-table key="id" :data="form.orderExceptionDetailList" style="width: 100%;padding: 0;" row-key="id"
                        border>
                        <el-table-column type="index" :label="td('序号')" width="50">
                        </el-table-column>
                        <el-table-column :label="td('货品')" prop="goodsName">
                            <template slot-scope="scope">
                                <span>{{ scope.row.goodsName }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="SELLER_SKU" prop="custGoodsCode">
                            <template slot-scope="scope">
                                <span>{{ scope.row.custGoodsCode }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="仓库SKU" prop="goodsBarcode">
                            <template slot-scope="scope">
                                <span>{{ scope.row.goodsBarcode }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="异常类型" prop="goodsBarcode">
                            <template slot-scope="scope">
                                <span>{{ tfp('EXCEPTION_TYPE', scope.row.orderExceptionDetailType) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="问题数量" prop="forecastNum">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.exceptionNum" disabled />
                            </template>
                        </el-table-column>
                        <el-table-column label="处理方案" width="260">
                            <template slot-scope="scope">
                                <el-form-item :prop="`orderExceptionDetailList.${scope.$index}.handleScheme`"
                                    :rules="{ required: true, trigger: 'blur', message: '', }">
                                    <el-select clearable filterable size="small" v-model="scope.row.handleScheme"
                                        :placeholder="td('请选择')">
                                        <el-option v-for="item in getHandleScheme(scope.row)" :key="item.detailId"
                                            :label="item.langTranslate" :value="item.detailId" />
                                    </el-select>
                                </el-form-item>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
                <el-form-item style="margin-top:20px" :label="td('客户处理意见')" prop="custComments">
                    <el-input v-model="form.custComments" type="textarea" :placeholder="td('请输入内容')" />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm_op">{{ td("确定") }}</el-button>
                <el-button @click="cancel">{{ td("取消") }}</el-button>
            </div>
        </el-dialog>


    </div>
</template>

<script>

import { getList, getListDetail, getListSelectDetail, setOrderException } from "@/api/wms/orderException";
export default {
    name: "warehouseList",
    data() {
        return {
            is_orNotHandle: [{ "id": "0", "name": this.td('未处理') }, { "id": "1", "name": this.td('已处理') }],
            input: "",
            radio: "",
            total: 0,

            // 表单参数
            form: {},
            // 控制弹框
            addhouseDig: false,
            matchRulesDig: false,
            //仓库数据
            getTableList: [],
            queryParams: {
                billNo: null,
                opFlag: null,
                orderExceptionType: null,
                pageNum: 1,
                pageSize: 10,
            },
            plList: [],
            open_op: false,
            title: '',
            // 表单校验
            rules: {
                opState: [
                    { required: true, message: this.td("'处理意见'不能为空"), trigger: "blur" }
                ],
                custComments: [
                    { required: true, message: this.td("'客户处理意见'不能为空"), trigger: "blur" }
                ]
            }
        }
    },
    created() {
        this.sonSkuListss = new Map()
        this.getDepotInfo();
    },
    computed: {
        getHandleScheme() {
            return function (row) {
                let isArray = this.tf('EXCEPTION_HANDLE') instanceof Array
                if(!isArray) return []
                if (row.orderExceptionDetailType == 4925) {
                    return this.tf('EXCEPTION_HANDLE').filter(item => item.detailId == 4929)
                } else {
                    return this.tf('EXCEPTION_HANDLE').filter(item => item.detailId != 4929)
                }
            }
        }
    },
    methods: {
        // 表单重置
        reset() {
            this.form = {};
            this.resetForm("form");
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.open_op = false;
            this.reset();
        },
        submitForm_op() {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    // this.form.inOrderId=this.idsOp[0].inOrderId;
                    // console.log(this.form)
                    let { custComments, inOrderExceptionId, orderExceptionDetailList } = this.form;
                    let form = {
                        custComments,
                        inOrderExceptionId,
                        orderExceptionDetailList: orderExceptionDetailList.map(item => ({ handleScheme: item.handleScheme, orderExceptionDetailId: item.id }))
                    }
                    setOrderException(form).then(es => {
                        if (es != undefined && es != "undefined" && es.code == 200) {
                            this.$message.success(this.td("处理成功"));
                            this.open_op = false;
                        }
                        this.getList();
                    })
                }
            })
        },
        //处理
        handleImport() {
            // console.log(this.plList)
            if (this.plList[0].opFlag != 1) {
                let inOrderExceptionId = this.plList[0].id;
                getListSelectDetail(inOrderExceptionId).then(response => {
                    this.form = {
                        inOrderExceptionId, //异常单ID
                        orderExceptionDetailList: response.rows.map(item => ({ ...item, handleScheme: null })), //多个处理单集合
                        custComments: ''
                    }
                    this.open_op = true;
                    this.title = this.td("处理异常单");
                })
            } else {
                this.msgError(this.td("异常单已处理"));
            }

        },
        // 查看详情
        toogleExpand(row) {
            // console.log(row)
            getListDetail(row.id).then(response => {
                this.sonSkuListss.set(row.id, response.rows)
                let $table = this.$refs.table
                $table.toggleRowExpansion(row)
            })
        },
        //多选
        handleSelectionChange(selection) {
            this.plList = selection;
        },
        // 获取所有仓库数据
        getDepotInfo() {
            // var erpAccount = this.$store.state.user.name
            let orderExceptionType = this.queryParams.orderExceptionType;
            let queryParams = {
                ...this.queryParams
            }
            if (orderExceptionType) queryParams.orderExceptionType = orderExceptionType.join()
            getList(queryParams).then(res => {
                this.getTableList = res.rows;
                this.getTableList.forEach(item=>{
                let urls = item.orderExceptionUrl && item.orderExceptionUrl.split(',');
                item.imgUrls = urls;
                item.curImg = urls && urls.length && urls[0];
                })
                this.total = res.total;
                // console.log(res);
            })
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1
            this.getDepotInfo();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.queryParams = {
                opFlag:null,
                orderExceptionType:null,
                pageNum:1,
                pageSize:10
            };
            this.resetForm("queryForm")
            this.handleQuery();
        },
        //修改仓库
        handleUpdate(id) {
            // console.log(id);
        },
        //删除仓库
        handleDelete(id) {
            // console.log(id);
        },
        //更新
        // updateList() {
        //   this.getDepotInfo();
        // },

    },
};
</script>
  
<style scoped>
.box {
    position: relative;
    height: 80px;
    margin: 24px 0 16px 0;
    background-color: #fff;
}

.box .el-form-item .el-tbutton {
    margin-left: 16px;
}

.el-form {
    position: absolute;
    top: 20px;
    left: 16px;

}

::v-deep .el-form-item__label {
    margin-right: 24px;
    padding: 0;
}

.el-form-item:nth-child(2) {
    margin-left: 16px;
}

.el-button:nth-child(1) {
    margin-right: 16px;
}

.box .el-input {
    width: 220px;
}

.el-table {
    padding: 20px 16px 0 16px;
}

/* 
.el-button {
    width: 74px;
    height: 32px;
} */

.el-form--inline .el-form-item {
    margin-right: 0px
}

::v-deep .el-button+.el-button {
    margin-left: 0px;
}

.threeBtns {
    background-color: #fff;
    padding: 24px 16px;
}

.my-form {
    position: relative;
}
</style>