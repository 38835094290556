import request from '@/utils/request'
import { getToken } from "@/utils/auth"

// 异常列表查询接口
export function getList(params) {
  return request({
    url: '/erp/OrderException/list',
    headers: {"Authorization": "Bearer  " + getToken()},
    method: 'get',
	params
  })
}

// 异常明查询细接口
export function getListDetail(orderExceptionId) {
  return request({
    url: '/erp/OrderException/DetailList',
    headers: {"Authorization": "Bearer  " + getToken()},
    method: 'get',
	  params:{
      orderExceptionId
    }
  })
}

// 异常明查询细接口（处理用）
export function getListSelectDetail(orderExceptionId) {
  return request({
    url: '/erp/OrderException/SelectHandleDetail',
    headers: {"Authorization": "Bearer  " + getToken()},
    method: 'get',
	  params:{
      orderExceptionId
    }
  })
}

// 异常处理  ------>  保存接口
export function setOrderException(data) {
  return request({
    url: '/erp/OrderException',
    headers: {"Authorization": "Bearer  " + getToken()},
    method: 'post',
	  data
  })
}